:root {
  --accentColor: #ff9900;
  --mainFontFamily: 'Anton';
  --secondaryFont: 'Great Vibes', cursive;
}

[data-theme='light'] {
  /*colors*/

  --backgroundColor: whitesmoke;
  --backgroundColor2: white;
  --secondaryBackgroundColor: rgb(29, 29, 29);
  --shadowGlow: var(--backgroundColor2);
  --shadowGlow2: var(--backgroundColor2);
  --outlineColor: #e4e4e4;
  /* --shadowGlow: #ff990035; */

  /* font */
  --mainFontColor: #350400;

  /* containers */
  --outline: var(--outlineColor) 2px solid;
  --outlineGlow: #c7c7c7 2px solid;
  --outlineGlow2: var(--accentColor);

  /* profile */
  --pfp: url(/src/images/pfp\ 2.webp);

  /* loader */
  --invertLogo: 1;
  --transitionBack: white;
}

[data-theme='dark'] {
  /* --accentColor: #0dc; */
  --backgroundColor: rgb(17, 25, 28);
  --backgroundColor2: rgb(8, 16, 20);
  --backgroundColor3: rgb(21, 34, 39);
  --secondaryBackgroundColor: rgb(29, 29, 29);
  --mainFontColor: #d6dfdf;
  --outlineColor: rgb(34, 47, 50);
  --outline: var(--outlineColor) 2px solid;
  --transitionBack: rgb(11, 19, 23);
  --invertLogo: 0;
  --pfp: url(/src/images/pfp\ 1.webp);
  /* --shadowGlow: #ffffff80; */
  --shadowGlow: rgba(0, 221, 203, 0.3);
  --shadowGlow2: rgba(255, 195, 105, 0.2);
  --shadowGlow2High: rgba(255, 206, 132, 0.4);
  --outlineGlow: white 2px solid;
  --outlineGlow2: white;
}

a {
  cursor: url(./images/link.png), pointer;
}

a:link {
  color: white;
  background-color: transparent;
  text-decoration: underline;
}

a:visited {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

a:active {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

svg {
  cursor: url(./images/link.png), pointer;
}

::-webkit-scrollbar {
  background-color: transparent;
  position: absolute;
  z-index: 1;
  display: none;
}

html {
  /* scroll-behavior: smooth; */
  scroll-padding: 15%;
  overflow-x: hidden;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  /* text-rendering: geometricPrecision; */
  /* pointer-events: none; */
}

#smooth {
  transform: translate3d(var(--x), 0px);
  transition: 0.3s ease-out;
  padding: 5vw;
  padding-top: 4vw;
  overflow-anchor: none;
  backface-visibility: hidden;
}

body {
  background-image: url(images/BackGround.webp);
  background-color: var(--backgroundColor2);
  /* background-blend-mode: soft-light; */
  background-size: 20rem;
  animation: backMove linear 60s infinite alternate;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: var(--mainFontFamily);
  -webkit-font-smoothing: subpixel-antialiased;
  color: var(--mainFontColor);
  user-select: none;

  cursor: url(./images/pointer.png), default;
}
.alwaysForever {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: rgba(68, 18, 47, 0.9);
  /* transition: 4; */
  z-index: 100;
  font-family: var(--secondaryFont);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 10rem;
  font-weight: 600;
  text-shadow: 0 0 50px palevioletred;
  /* display: none; */
  /* text-overflow: clip; */
  /* text-wrap: wrap; */
  transition: 1s ease;
  opacity: 1;
  color: white;
}

.alwaysForever.classClose {
  opacity: 0;
  display: none;
}

.alwaysForever p {
  margin: 0;
  translate: 0 10%;
  transition: 4s ease-out;
  animation: SecBobbing 6s ease-out infinite;
}

.alwaysForever.classClose p {
  translate: 0 -200%;
}

#emoji {
  animation: Scalein 1s cubic-bezier(0.075, 0.82, 0.165, 1) infinite
    alternate-reverse;
  filter: drop-shadow(0 0 50px red);
  display: block;
}
#us {
  font-size: 0.5em;
}

.button {
  position: fixed;
  width: 50px;
  height: 50px;
  margin: 1vw;
  z-index: 5;
  padding: 0;
  border: none;
  background-color: var(--accentColor);
  border-radius: 50%;
  /* cursor: none; */
  cursor: url(./images/link.png), pointer;
  bottom: 0;
}

#themeToggle {
  left: 0;
}

#themeToggle img {
  object-fit: cover;
  width: 80%;
  height: 80%;
}

#themeToggle:focus img {
  animation: fullSpin 1.5s ease;
}

#themeToggle:active img {
  animation: none;
}

#infoToggle {
  right: 0;
}

.infoBubble {
  background-color: var(--accentColor);
  /* pointer-events: none; */
  position: absolute;
  z-index: 6;
  width: fit-content;
  max-width: 60ch;
  height: fit-content;
  bottom: 7svh;
  right: 7svh;
  border-radius: 30px 30px 5px 30px;
  font-family: 'Noto Sans', sans-serif;
  color: #350400;
  font-weight: 600;
  opacity: 1;
  transition: 1s ease, padding 0.1s;
  padding: 1em;
  padding-inline: 1.5em;
  text-wrap: nowrap;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  text-align: right;
  scale: 1;
  transform-origin: right bottom;
  cursor: url(images/pointer.png), default;
}

#infoToggle img {
  pointer-events: none;
  object-fit: contain;
  width: 80%;
  height: 80%;
  transition: 1s;
}

#infoToggle:hover img {
  scale: 1.1;
  transition: 0.2s;
}

.infoBubble.classClose {
  /* scale: 0; */
  max-width: 0;
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1), padding 1s 0.4s;
  padding-inline: 0;
  /* opacity: 0; */
  /* padding-inline: 0; */
}
