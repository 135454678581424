:root {
  --accentColor: #f90;
  --mainFontFamily: "Anton";
  --secondaryFont: "Great Vibes", cursive;
}

[data-theme="light"] {
  --backgroundColor: whitesmoke;
  --backgroundColor2: white;
  --secondaryBackgroundColor: #1d1d1d;
  --shadowGlow: var(--backgroundColor2);
  --shadowGlow2: var(--backgroundColor2);
  --outlineColor: #e4e4e4;
  --mainFontColor: #350400;
  --outline: var(--outlineColor) 2px solid;
  --outlineGlow: #c7c7c7 2px solid;
  --outlineGlow2: var(--accentColor);
  --pfp: url("pfp 2.fb288c61.webp");
  --invertLogo: 1;
  --transitionBack: white;
}

[data-theme="dark"] {
  --backgroundColor: #11191c;
  --backgroundColor2: #081014;
  --backgroundColor3: #152227;
  --secondaryBackgroundColor: #1d1d1d;
  --mainFontColor: #d6dfdf;
  --outlineColor: #222f32;
  --outline: var(--outlineColor) 2px solid;
  --transitionBack: #0b1317;
  --invertLogo: 0;
  --pfp: url("pfp 1.09969f55.webp");
  --shadowGlow: #00ddcb4d;
  --shadowGlow2: #ffc36933;
  --shadowGlow2High: #ffce8466;
  --outlineGlow: white 2px solid;
  --outlineGlow2: white;
}

a {
  cursor: url("link.6ebb9d0c.png"), pointer;
}

a:link {
  color: #fff;
  background-color: #0000;
  text-decoration: underline;
}

a:visited, a:hover, a:active {
  color: #fff;
  background-color: #0000;
  text-decoration: none;
}

svg {
  cursor: url("link.6ebb9d0c.png"), pointer;
}

::-webkit-scrollbar {
  z-index: 1;
  background-color: #0000;
  display: none;
  position: absolute;
}

html {
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: grayscale;
  scroll-padding: 15%;
  overflow-x: hidden;
}

#smooth {
  transform: translate3d(var(--x), 0px);
  overflow-anchor: none;
  backface-visibility: hidden;
  padding: 4vw 5vw 5vw;
  transition: all .3s ease-out;
}

body {
  background-image: url("BackGround.b268f1a4.webp");
  background-color: var(--backgroundColor2);
  text-align: center;
  font-family: var(--mainFontFamily);
  -webkit-font-smoothing: subpixel-antialiased;
  color: var(--mainFontColor);
  -webkit-user-select: none;
  user-select: none;
  cursor: url("pointer.ab5c0df9.png"), default;
  background-size: 20rem;
  background-attachment: fixed;
  margin: 0;
  padding: 0;
  animation: 60s linear infinite alternate backMove;
}

.alwaysForever {
  z-index: 100;
  font-family: var(--secondaryFont);
  text-shadow: 0 0 50px #db7093;
  opacity: 1;
  color: #fff;
  background-color: #44122fe6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 10rem;
  font-weight: 600;
  transition: all 1s;
  display: flex;
  position: fixed;
  top: 0;
}

.alwaysForever.classClose {
  opacity: 0;
  display: none;
}

.alwaysForever p {
  margin: 0;
  transition: all 4s ease-out;
  animation: 6s ease-out infinite SecBobbing;
  translate: 0 10%;
}

.alwaysForever.classClose p {
  translate: 0 -200%;
}

#emoji {
  filter: drop-shadow(0 0 50px red);
  animation: 1s cubic-bezier(.075, .82, .165, 1) infinite alternate-reverse Scalein;
  display: block;
}

#us {
  font-size: .5em;
}

.button {
  z-index: 5;
  background-color: var(--accentColor);
  cursor: url("link.6ebb9d0c.png"), pointer;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 1vw;
  padding: 0;
  position: fixed;
  bottom: 0;
}

#themeToggle {
  left: 0;
}

#themeToggle img {
  object-fit: cover;
  width: 80%;
  height: 80%;
}

#themeToggle:focus img {
  animation: 1.5s fullSpin;
}

#themeToggle:active img {
  animation: none;
}

#infoToggle {
  right: 0;
}

.infoBubble {
  background-color: var(--accentColor);
  z-index: 6;
  color: #350400;
  opacity: 1;
  text-wrap: nowrap;
  text-align: right;
  transform-origin: 100% 100%;
  cursor: url("pointer.ab5c0df9.png"), default;
  padding: 1em;
  border-radius: 30px 30px 5px;
  width: fit-content;
  max-width: 60ch;
  height: fit-content;
  padding-inline: 1.5em;
  font-family: Noto Sans, sans-serif;
  font-weight: 600;
  transition: all 1s, padding .1s;
  position: absolute;
  bottom: 7svh;
  right: 7svh;
  overflow: hidden;
  scale: 1;
  box-shadow: 0 0 20px #0003;
}

#infoToggle img {
  pointer-events: none;
  object-fit: contain;
  width: 80%;
  height: 80%;
  transition: all 1s;
}

#infoToggle:hover img {
  transition: all .2s;
  scale: 1.1;
}

.infoBubble.classClose {
  max-width: 0;
  padding-inline: 0;
  transition: all 1s cubic-bezier(.075, .82, .165, 1), padding 1s .4s;
}
/*# sourceMappingURL=index.6be17465.css.map */
